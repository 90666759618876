.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.userselect {
    display: contents;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    border-radius: 0.375rem;
    opacity: .5;
    color: #000;
    background: transparent url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E) 50%/1em auto no-repeat;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;
    }

    .modal-sm {
        --bs-modal-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }
}

.MuiButton-primaryPrimary {
    background-color: cornflowerblue !important;
}

.css-zumo0w-MuiPaper-root-MuiDrawer-paper {
    background-color: #ffffff !important;
    color: #03060b !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    box-shadow: none !important;
    overflow-y: auto !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    height: 100% !important;
    -webkit-flex: 1 0 auto !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
    z-index: 1200 !important;
    -webkit-overflow-scrolling: touch !important;
    position: fixed !important;
    top: 0 !important;
    outline: 0 !important;
    left: 0 !important;
    border-right: 1pxsolidrgba (0, 0, 0, 0.12) !important;
    background-color: #ffffff !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    padding: 8px !important;
}

.containerorder {
    margin-top: 20px;
    height: 100%;
    min-height: 100%;
}

.button-wrap {
    position: relative;
    text-align: center;
    top: 50%;
    margin-top: -2.5em;
}

@media (max-width: 40em) {
    .button-wrap {
        margin-top: -1.5em;
    }
}

.button-label {
    display: inline-block;
    padding: 1em;
    width: 6em;
    margin: 0.5em;
    cursor: pointer;
    color: #292929;
    border-radius: 0.25em;
    background: #efefef;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-label h1 {
    font-size: 1em;
}

.button-label:hover {
    background: #d6d6d6;
    color: #101010;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
}

.button-label:active {
    transform: translateY(2px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);
}

@media (max-width: 40em) {
    .button-label {
        padding: 0em 1em 3px;
        margin: 0.25em;
    }
}

#a-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#a-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#b-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#b-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#c-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#c-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#d-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#d-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#e-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#e-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#f-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#f-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#g-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#g-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#h-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#h-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#i-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#i-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#j-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#j-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#k-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#k-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

#l-button:checked+.button-label {
    background: #4183d7;
    color: #efefef;
}

#l-button:checked+.button-label:hover {
    background: #2c75d2;
    color: #e2e2e2;
}

.hidden {
    display: none;
}

select.select {
    /* Reset */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: -webkit-fill-available;
    margin: 1em;
    height: 3em;
    padding: 0 4em 0 1em;
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg) no-repeat right 0.8em center/1.4em, linear-gradient(to left, rgba(98, 192, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
    color: black;
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* <option> colors */
    /* Remove focus outline */
    /* Remove IE arrow */
}

select option {
    color: inherit;
    background-color: #fdfdfd;
    font-size: 15px;
}

select:focus {
    outline: none;
}

select::-ms-expand {
    display: none;
}

textarea::placeholder {
    color: #000;
    font-size: 15px;
}

textarea {
    display: block;
    box-sizing: border-box;
    width: -webkit-fill-available;
    ;
    resize: none;
    overflow-y: hidden;
    font-family: inherit;
    font-size: 15px;
    /* optional CSS */
    outline: 0;
    background: white;
    border: 0;
    border-radius: 5px;
    padding: 20px;
    font-weight: normal;
    color: #000;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    margin: 1em
}

.button {
    border: none;
    color: white;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 50px;
    border-radius: 3px;
    margin: 5px;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    transition: text-shadow 0.3s ease;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}

.green {
    background-color: #4CDF78;
    border-color: #53A574;
}
.green:hover {
    background-color: #3aff75;
    border-color: #8cbea0;
}

.css-1ur56ht-MuiButtonBase-root-MuiButton-root {
    margin: 10px !important;
}

input[type="date"] {
    display: block;
    position: relative;
    padding: 1rem 3.5rem 1rem 0.75rem;
    width: 20%;
    font-size: 1rem;
    font-family: monospace;
    height: 7vh;
    border: 1px solid #8292a2;
    border-radius: 0.25rem;
    background:
        white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E") right 1rem center no-repeat;

    cursor: pointer;
}

input[type="date"]:focus {
    outline: none;
    border-color: #3acfff;
    box-shadow: 0 0 0 0.25rem rgba(0, 120, 250, 0.1);
}

::-webkit-datetime-edit {}

::-webkit-datetime-edit-fields-wrapper {}

::-webkit-datetime-edit-month-field:hover,
::-webkit-datetime-edit-day-field:hover,
::-webkit-datetime-edit-year-field:hover {
    background: rgba(0, 120, 250, 0.1);
}

::-webkit-datetime-edit-text {
    opacity: 0;
}

::-webkit-clear-button,
::-webkit-inner-spin-button {
    display: none;
}

::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    cursor: pointer;

    color: rgba(0, 120, 250, 1);
    background: rgba(0, 120, 250, 1);

}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
    opacity: 0.05;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
    opacity: 0.15;
}

.dateselect{
    display: flex;
}
